.enrollment {
  &__hero {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 45.556em;
    padding-bottom: 4.306em;
    &__content {
      display: flex;
      justify-content: space-between;
      &__description {
        width: 45em;
        &__text {
          margin-top: 1.5em;
        }
        &__help-text {
          margin-top: 2.333em;
          margin-bottom: .75em;
        }
      }
      &__center-list {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 9.2em;
        margin-top: 2.5em;
        margin-bottom: 3.681em;
        &__center-container {
          margin-top: 2.223em;
          &:first-child {
            margin-top: 0;
          }
          &:nth-child(4) {
            margin-top: 0;
          }
        }
        &__center {
          display: flex;
          text-decoration: none;
          width: max-content;
        }
      }
      &__container-visiting {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &__visiting {
        width: 35.422em;
        height: 33.773em;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: relative;
        display: flex;
        justify-content: center;
        &-text-block {
          width: 23.743em;
          height: 9.137em;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0;
          &__text {
            font-family: 'Zen Dots';
            font-size: 2.5rem;
            text-align: center;
            line-height: .88em;
          }
        }
      }
    }
  }
  &__right-now {
    display: flex;
    justify-content: center;
    padding-top: 1.181em;
    padding-bottom: 3.889em;
    &__content {
      display: flex;
      justify-content: space-between;
      &__image {
        width: 39.862em;
        & img {
          width: 100%;
        }
      }
    }
    &__description {
      width: 40.834em;
      padding-top: 5.07em;
      &__text {
        margin-top: .5em;
        margin-bottom: 1.5em;
      }
    }
  }
  &__available-courses {
    display: flex;
    justify-content: center;
    padding-top: 4.445em;
    padding-bottom: 5em;
    &__content {
      color: white;
    }
    &__title {
      margin-bottom: .8123em;
    }
    &__description-container {
      width: 45.8em;
      margin-bottom: 2.084em;
    }
    &__plans-container {
      display: grid;
      grid-template-areas: 'plans-page__entrepreneurship-technology-registration-plan plans-page__individual-more-hours-plan'
                           'plans-page__container__middle-line plans-page__container__middle-line'
                           'plans-page__english-online-monthly plans-page__english-online-quarterly'
                           'plans-page__container__middle-line-2 plans-page__container__middle-line-2'
                           'plans-page__entrepreneurship-technology-english-monthly plans-page__entrepreneurship-technology-english-quarterly'
                           'plans-page__container__middle-line-3 plans-page__container__middle-line-3'
                           'plans-page__videogames-online-monthly plans-page__videogames-online-quarterly'
                           'plans-page__container__middle-line-4 plans-page__container__middle-line-4'
                           'plans-page__entrepreneurship-technology-presential-monthly plans-page__entrepreneurship-technology-presential-quarterly'
                           'plans-page__container__middle-line-5 plans-page__container__middle-line-5'
                           'plans-page__english-presential-monthly plans-page__english-presential-quarterly'
                           'plans-page__container__middle-line-6 plans-page__container__middle-line-6'
                           'plans-page__entrepreneurship-technology-english-presential-monthly plans-page__entrepreneurship-technology-english-presential-quarterly'
                           'plans-page__container__middle-line-7 plans-page__container__middle-line-7'
                           'plans-page__videogames-presential-monthly plans-page__videogames-presential-quarterly';
      // flex-wrap: wrap;
      justify-content: center;
      column-gap: 5em;
      row-gap: 5em;
      & .entrepreneurship-registration-plan {
        justify-self: flex-end;
        align-self: flex-end;
      }
      & .entrepreneurship-technology-registration-plan,
      & .english-online-monthly,
      & .entrepreneurship-technology-english-monthly,
      & .videogames-online-monthly,
      & .entrepreneurship-technology-presential-monthly,
      & .english-presential-monthly,
      & .entrepreneurship-technology-english-presential-monthly,
      & .videogames-presential-monthly {
        justify-self: flex-end;
      }
    }
    &__contact-us {
      margin-top: 5em;
      // padding-bottom: 19.028em;
      padding-bottom: 21em;
      & .plans-container__middle-line {
        margin-bottom: 5em;
      }
      &__content {
        display: flex;
        position: relative;
      }
      &__text-container {
        width: 29.584em;
      }
      &__image {
        width: 62.917em;
        position: absolute;
        right: -2.084em;
        & > img {
          width: 100%;
        }
      }
      &__subtitle {
        font-family: 'Zen Dots';
        font-weight: 400;
        margin-bottom: .5em;
      }
      &__text-description {
        width: 12.248em;
        margin-top: 1em;
        margin-bottom: 2.333em;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .enrollment {
    &__hero {
      height: max-content;
      padding-top: 5.016em;
      &__content {
        flex-direction: column;
        &__description {
          width: 100%;
        }
        &__center-list {
          height: max-content;
          &__center-container:nth-child(4) {
            margin-top: inherit;
          }
        }
      }
    }

    &__right-now {
      &__content {
        flex-direction: column;
      }
    }

    &__available-courses {
      &__description-container {
        width: 100%;
      }
      &__plans-container {
        grid-template-areas: 'plans-page__entrepreneurship-technology-registration-plan'
                             'plans-page__individual-more-hours-plan'
                             'plans-page__container__middle-line'
                             'plans-page__english-online-monthly'
                             'plans-page__english-online-quarterly'
                             'plans-page__container__middle-line-2'
                             'plans-page__entrepreneurship-technology-english-monthly'
                             'plans-page__entrepreneurship-technology-english-quarterly'
                             'plans-page__container__middle-line-3'
                             'plans-page__videogames-online-monthly'
                             'plans-page__videogames-online-quarterly'
                             'plans-page__container__middle-line-4'
                             'plans-page__entrepreneurship-technology-presential-monthly'
                             'plans-page__entrepreneurship-technology-presential-quarterly'
                             'plans-page__container__middle-line-5'
                             'plans-page__english-presential-monthly'
                             'plans-page__english-presential-quarterly'
                             'plans-page__container__middle-line-6'
                             'plans-page__entrepreneurship-technology-english-presential-monthly'
                             'plans-page__entrepreneurship-technology-english-presential-quarterly'
                             'plans-page__container__middle-line-7'
                             'plans-page__videogames-presential-monthly'
                             'plans-page__videogames-presential-quarterly';
        // column-gap: 0;
      }
      &__contact-us {
        // padding-bottom: 5.016em;
        padding-bottom: 0;
        &__content {
          flex-direction: column;
        }
        &__image {
          position: relative;
          right: 0;
          width: 100%;
          margin-top: 1.673em;
        }
      }
    }
  }
}